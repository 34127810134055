<template>
  <div>
    <v-app-bar
      flat
      color="transparent"
      class="my-sm-12"
    >
      <v-container>
        <v-row v-if="$vuetify.breakpoint.mobile" justify="end">
          <v-col cols="auto" class="px-0">
            <v-icon @click="dialog = !dialog">mdi-menu</v-icon>
          </v-col>
        </v-row>
        <v-row justify="center" v-else>
          <v-col cols="auto" class="px-0">
            <v-sheet
              outlined 
              class="rounded-pill"
            >
              <v-btn
                v-for="(menu, index) in menus"
                :key="index"
                link
                large
                plain
                rounded
                exact-path
                active-class="teal--text"
                class=""
                :to="menu.link"
              >{{ menu.title }}</v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-dialog v-model="dialog" content-class="menu-dialog">
      <v-card class="rounded-xl px-2">
        <v-list>
          <v-subheader>
            메뉴
            <v-spacer></v-spacer>
            <v-icon @click="dialog = !dialog">mdi-close</v-icon>
          </v-subheader>
          <v-list-item
            v-for="(menu, index) in menus"
            :key="index"
            @click="$router.push(menu.link); dialog = false;"
          >
            <v-list-item-title>
              {{ menu.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AppBar',
  data: () => ({
    dialog: false,
    menus: [
      { title: '홈', link: '/' },
      { title: '소개', link: '/about' },
      { title: '공지사항', link: '/notice' },
      { title: '뉴스', link: '/news' },
      { title: '칼럼', link: '/column' },
      { title: '보고서', link: '/report' },
      { title: '행사', link: '/event' }, 
      { title: '캘린더', link: '/calendar' },
    ],
    searchText: '',
  }),
  methods: {
    search () {
      return;
    }
  }
}
</script>

<style scoped>
:deep() .menu-dialog {
  margin-top: 40px;
  align-self: flex-start;
}
</style>
