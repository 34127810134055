<template>
  <footer>
    <v-card>
      <v-card-text class="text-center">
        ⓒ {{ new Date().getFullYear() }} 기후위기부천비상행동
      </v-card-text>
    </v-card>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
}
</script>
