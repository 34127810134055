<template>
  <v-container>
    <v-row class="mt-8">
      <v-col cols="12" sm="8">
        <v-row>
          <v-col
            v-for="(post, index) in posts"
            :key="index"
            cols="12"
          >
            <PostCard
              :image="post.image"
              :title="post.title"
              :text="post.text"
              :date="post.date"
              :link="post.link"
              :category="post.category"
            />
          </v-col>
          <v-col v-if="posts.length === 0" cols="12">
            게시물이 없습니다.
          </v-col>
          <v-col v-else cols="12">
            <v-btn
              outlined
              block
              rounded
              color="grey lighten-1"
              @click="load"
            >
              <span class="black--text">더보기</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4" v-if="isHome">
        <v-row>
          <v-col cols="12">
            <ChipCard/>
          </v-col>
          <v-col cols="12">
            <EventCard/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import HomeWidget from '@/components/HomeWidget.vue';
import PostCard from '@/components/cards/PostCard.vue';
import EventCard from '@/components/cards/EventCard.vue';
import ChipCard from '@/components/cards/ChipCard.vue';
import { getFirstImage, getCategory, getRoute } from '@/scripts/utilities.js';

export default {
  name: 'ListView',
  components: {
    // HomeWidget,
    PostCard,
    EventCard,
    ChipCard,
  },
  data() {
    return {
      posts: [],
    }
  },
  computed: {
    isHome() {
      return getRoute(this) === 'home';
    },
  },
  created() {
    this.load();
  },
  watch: {
    $route() {
      this.posts.length = 0;
      this.load();
    }
  },
  methods: {
    load () {
      const category = this.isHome ? '' : `categories=${getCategory(this.$route.params.category).id}&`;
      const page = Math.floor(this.posts.length / 5) + 1;
      fetch(`https://bcclimate.arkaive.org/wp-json/wp/v2/posts?${category}page=${page}&per_page=5&_fields=id,title,content,excerpt,date,categories`)
        .then((r) => r.json())
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.posts.push({
              image: getFirstImage(data[i].content.rendered),
              title: data[i].title.rendered,
              text: data[i].excerpt.rendered,
              date: data[i].date.split('T')[0],
              link: `/page/${data[i].id}`,
              category: getCategory(data[i].categories[0]).kor,
            });
          }
        });
    }
  }
}
</script>
