<template>
  <v-card 
    outlined
    height="365"
    class=""
  >
    <v-card-text class="teal--text pl-6">
      참여단체
    </v-card-text>
    <v-card-text class="pt-0 overflow-y-auto" style="height: 310px;">
      <v-chip
        v-for="(org, index) in organizations"
        :key="index"
        outlined
        link
        :href="org.link"
        target="blank"
        class="mr-2 mb-2"
      >
        {{ org.title }}
        <v-icon 
          v-if="org.link"
          small
          class="pl-1"
        >mdi-open-in-new</v-icon>
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ChipCard',
  data: () => ({
    organizations: [
      { title: '부천YWCA', link: 'https://ywcabucheon.org' },
      { title: '부천YMCA', link: 'http://www.bcymca.or.kr/' },
      { title: '피스오브피스', link: 'https://www.piecepeace.org/' },
      { title: '부천시민연합', link: 'http://www.puchon.or.kr/' },
      { title: '평화미래플랫폼 파란', link: 'https://ko-kr.facebook.com/groups/2709651722379064/' },
      { title: '부천민예총', link: 'https://www.facebook.com/bc416' },
      { title: '남북평화재단 부천본부', link: 'https://m.cafe.daum.net/snpeacebc/_rec?page=2' },
      { title: '뜰안에 작은나무(뜰작)', link: 'https://www.smalllibrary.org/library/detail/1049338' },
      { title: '아시아인권문화연대', link: '' },
      { title: '평화와 자치를 열어가는 부천연대', link: 'https://bsps0727.modoo.at/' },
      { title: '동부천IC반대 주민대책위', link: '' },
      { title: '부천민중연대', link: 'https://www.google.com/search?q=%EB%B6%80%EC%B2%9C%EB%AF%BC%EC%A4%91%EC%97%B0%EB%8C%80&rlz=1C5CHFA_enKR993KR993&oq=%EB%B6%80%EC%B2%9C%EB%AF%BC%EC%A4%91%EC%97%B0%EB%8C%80&aqs=chrome..69i57j69i59l3.2457j0j4&sourceid=chrome&ie=UTF-8' },
      { title: '부천종교인평화회의', link: '' },
      { title: '한국기독교장로회 부천시찰회', link: '' },
      { title: '지평교회(부천환경교육센터)', link: 'https://m.place.naver.com/place/21168212/home' },
      { title: '제자교회', link: '' },
      { title: '사랑의교회', link: '' },
      { title: '상동감리교회', link: 'http://www.sangdong.org/' },
      { title: '일터선교교회', link: '' },
      { title: '성가소비녀회 인천관구', link: 'http://www.holyfamily.or.kr/' },
      { title: '원불교 부천교당', link: 'http://bucheon.grandculture.net/bucheon/toc/GC01600503' },
      { title: '원불교 약대교당', link: 'http://bucheon.grandculture.net/bucheon/toc/GC01600503' },
      { title: '석왕사', link: 'http://bucheon.grandculture.net/bucheon/search?keyword=%EC%84%9D%EC%99%95%EC%82%AC' },
      { title: '보나벤투라 OFS', link: 'http://www.ofskorea.org/xe/' },
      { title: '경기한두레협동조합', link: 'http://www.bsdure.or.kr/' },
      { title: '부천의료복지사회적협동조합', link: 'http://bcmedcoop.org/' },
      { title: '산제로협동조합', link: 'https://www.instagram.com/sanzeroshop/' },
      { title: '부천시민햇빛발전협동조합', link: '' },
      { title: '경기두레생협', link: 'http://www.bsdure.or.kr/' },
      { title: '부천시민아이쿱생협', link: 'http://civilcoop.or.kr/' },
      { title: '부천아이쿱생협', link: 'http://www.bc-coop.or.kr/board/index.php' },
      { title: '모지리협동조합', link: 'https://www.instagram.com/mojiri_doorman/' },
      { title: '마을협동조합 소란', link: 'https://cafe.naver.com/sorancoop' },
      { title: '콩나물신문협동조합', link: 'https://www.kongnews.net/' },
      { title: '공정무역카페 "마음을담다"', link: 'https://m.place.naver.com/restaurant/1263513834/home' },
      { title: '부천청소년인권공동체 세움', link: 'https://cafe.daum.net/10nolto' },
      { title: '산학교', link: 'https://sanschool.net/' },
      { title: '부천교육사회적협동조합', link: 'http://www.bceducoop.com/' },
      { title: '부천교육희망네트워크', link: 'https://cafe.naver.com/hopeforeducation' },
      { title: '청개구리협동조합', link: 'https://ion.or.kr/gfrog9037/content/program' },
      { title: '부천교육문화재단', link: 'http://bcyyf.or.kr' },
      { title: '*부천교육청', link: '' },
      { title: '부천시청소년성문화센터', link: 'http://www.bchello.co.kr/' },
      { title: '혜윰 작은도서관', link: 'https://www.smalllibrary.org/library/detail/2666344?tab=forum' },
      { title: '부천시사회복지협의회', link: 'http://bucheon.grandculture.net/bucheon/dir/GC01600409' },
      { title: '부천시지역아동센터연합회', link: 'https://cafe.daum.net/gongbupang' },
      { title: '고강종합사회복지관', link: 'https://gogangwc.or.kr/' },
      { title: '한국노총 부천김포지역지부 ', link: 'http://buno.org/' },
      { title: '부천여성노동자회', link: 'https://blog.naver.com/pwwa22' },
      { title: '정의당 부천시갑위원회', link: 'https://www.justice21.org/newhome/region/committee.html?cafe_code=2851' },
      { title: '정의당 부천시을위원회', link: 'https://www.justice21.org/newhome/region/committee.html?cafe_code=2850' },
      { title: '정의당 부천시병위원회', link: 'https://www.justice21.org/newhome/region/committee.html?cafe_code=2853' },
      { title: '정의당 부천시정위원회', link: 'https://www.justice21.org/newhome/region/committee.html?cafe_code=2852' },
      { title: '정치하는 엄마들 부천모임', link: 'https://www.politicalmamas.kr/%ED%83%9C%EA%B7%B8-178' },
    ]
  })
}
</script>

