<template>
  <v-container>
    <v-row class="mt-sm-16">
      <v-col cols="12" class="py-0">
        <div 
          class="d-flex"
          style="min-height: 100vh;"
        >
          <div 
            v-if="title"
            class="mx-auto"
            style="max-width: 872px;"
          >
            <h1 :style="`font-size: ${$vuetify.breakpoint.mobile ? '2' : '3'}rem`">{{ title }}</h1>
            <div class="text-overline">| 기후위기부천비상행동</div>
            <div v-html="content" class="content py-10"/>
          </div>
        </div>
      </v-col>
      <v-col cols="auto" class="mx-auto">
        <v-btn 
          color="grey darken-1"
          outlined
          rounded
          @click="share"
        >
          <span class="black--text">
            공유하기
          </span>  
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirstImage } from '@/scripts/utilities';

export default {
  name: 'PageView',
  metaInfo() {
    return {
      title: `소개 | 기후위기부천비상행동`,
      meta: [
        { property: 'og:title', content: '소개 | 기후위기부천비상행동' },
        { property: 'og:description', content: '부천에서도 기후위기를 걱정하는 시민들이 모여 "기후위기 부천비상행동"을 시작합니다.' },
        { property: 'og:image', content: this.content ? getFirstImage(this.content) : window.location.origin + '/logo.png' },
      ]
    }
  },
  data() {
    return {
      title: '',
      content: '',
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      fetch(`https://bcclimate.arkaive.org/wp-json/wp/v2/pages/146?_fields=title,content`)
        .then((r) => r.json())
        .then((data) => {
          this.title = data.title.rendered;
          this.content = data.content.rendered;
        });
    },
    share () {
      console.log(this.$route);
      navigator.share({
        title: '부천기후위기비상행동',
        text: `[${this.category}] ${this.title}`,
        url: ''
      });
    }
  }
}
</script>

<style scoped>
.content :deep() img {
  max-width: 100% !important;
  object-fit: none !important;
  height: auto;
}

.content :deep() * {
  font-family: 'Noto Sans KR', sans-serif;
}
</style>