<template>
  <v-container>
    <v-row class="mt-sm-16">
      <v-col cols="12" class="py-0">
        <div 
          class="d-flex"
          style="min-height: 100vh;"
        >
          <div 
            v-if="title"
            class="mx-auto"
            style="max-width: 872px;"
          >
            <h1 :style="`font-size: ${$vuetify.breakpoint.mobile ? '2' : '3'}rem`">[{{ category }}] {{ title }}</h1>
            <div class="text-overline">| {{ date }}</div>
            <div v-html="content" class="content py-10"/>
          </div>
        </div>
      </v-col>
      <v-col cols="auto" class="mx-auto">
        <v-btn 
          color="grey darken-1"
          outlined
          rounded
          @click="share"
        >
          <span class="black--text">
            공유하기
          </span>  
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirstImage, getCategory } from '@/scripts/utilities';

export default {
  name: 'PageView',
  metaInfo() {
    return {
      title: `[${this.category}] ${this.title} | 기후위기부천비상행동`,
      meta: [
        { property: 'og:title', content: '기후위기부천비상행동' },
        { property: 'og:description', content: `[${this.category}] ${this.title}` },
        { property: 'og:image', content: this.content ? getFirstImage(this.content) : window.location.origin + '/logo.png' },
      ]
    }
  },
  data() {
    return {
      category: '',
      title: '',
      date: '',
      content: '',
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      fetch(`https://bcclimate.arkaive.org/wp-json/wp/v2/posts/${this.$route.params.id}?_fields=id,title,content,excerpt,date,categories,author`)
        .then((r) => r.json())
        .then((data) => {
          this.category = getCategory(data.categories[0]).kor;
          this.title = data.title.rendered;
          this.date = data.date.split('T').join(' ');
          this.content = data.content.rendered;
        });
    },
    share () {
      console.log(this.$route);
      navigator.share({
        title: '부천기후위기비상행동',
        text: `[${this.category}] ${this.title}`,
        url: ''
      });
    }
  }
}
</script>

<style scoped>
.content :deep() img {
  max-width: 100% !important;
  object-fit: none !important;
  height: auto;
}

.content :deep() * {
  font-family: 'Noto Sans KR', sans-serif;
}
</style>