<template>
  <v-app>
    <AppBar/>
    <HeroSection
      v-if="!['page', 'about'].includes(this.$route.name)"
    />
    <v-main>
      <router-view/>
    </v-main>
    <FooterSection/>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue';
import HeroSection from '@/components/HeroSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: '기후위기부천비상행동',
      htmlAttrs: {
        lang: 'ko-KR',
      },
      meta: [
        { name: 'description', content: '기후위기에 심각성을 느낀 전세계 185개국에서 일어나고 있는 “기후위기 비상행동(Climate strike)”.부천에서도 기후위기를 걱정하는 시민들이 모여 "기후위기 부천비상행동"을 시작합니다.지난 9월 첫걸음 캠페인을 시작으로 한 기후위기 부천비상행동은 현재 50여개의 부천시민사회단체와 함께하고 있습니다.' },
        { property: 'og:title', content: '기후위기부천비상행동' },
        { property: 'og:description', content: '기후위기에 심각성을 느낀 전세계 185개국에서 일어나고 있는 “기후위기 비상행동(Climate strike)”.부천에서도 기후위기를 걱정하는 시민들이 모여 "기후위기 부천비상행동"을 시작합니다.지난 9월 첫걸음 캠페인을 시작으로 한 기후위기 부천비상행동은 현재 50여개의 부천시민사회단체와 함께하고 있습니다.' },
        { property: 'og:image', content: window.location.origin + '/logo.png' },
        { property: 'og:url', content: window.location.origin + this.$route.path },
      ]
    }
  },
  components: {
    AppBar,
    HeroSection,
    FooterSection,
  }
}
</script>

<style>
.container {
  max-width: 1280px;
  /* padding: 0px; */
}
</style>
