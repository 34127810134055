<template>
  <v-card
    link
    outlined
    width="100%"
    @click="$router.push(link)"
  >
    <v-img :src="image" max-height="300"/>
    <v-card-title class="font-weight-bold pb-4">[{{ category }}] {{ title }}</v-card-title>
    <v-card-text v-html="text" class="pb-0"/>
    <v-card-text class="teal--text py-0">자세히 보기 →</v-card-text>
    <v-card-text class="text-caption grey--text">{{ date }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PostCard',
  props: {
    image: String,
    title: String,
    text: String,
    date: String,
    link: String,
    category: String,
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
}
</script>
