import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '@/views/AboutView.vue'
import ListView from '@/views/ListView.vue'
import PageView from '@/views/PageView.vue'
import CalendarView from '@/views/CalendarView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ListView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/:category',
    name: 'category',
    component: ListView
  },
  {
    path: '/page/:id',
    name: 'page',
    component: PageView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
