<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8">
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            :disabled="loading"
            @click="prev"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span 
            v-if="$refs.calendar"
            class="font-weidht-bold"
            style="font-size: 24px"
          >
            {{ $refs.calendar.title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-2"
            :disabled="loading"
            @click="next"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="400">
          <v-calendar
            v-model="value"
            ref="calendar"
            color="teal lighten-5"
            @change="getEvents"
            @click:date="showEvent"
          >
            <template #day="{ date }">
              <div
                v-if="events.some(e => between(date, e.start_date, e.end_date))"
                class="d-flex justify-center"
                @click="showEvent(date)"
              >
                <v-icon color="teal">mdi-circle-medium</v-icon>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card outlined :height="$vuetify.breakpoint.mobile ? '' : '100%'">
          <v-list>
            <v-subheader>예정된 이벤트</v-subheader>
            <v-list-item
              v-for="(event, index) in selected"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-subtitle class="text-caption">
                  {{ event.start_date }} ~ {{ event.end_date }}
                </v-list-item-subtitle>
                <v-list-item-title class="mt-2">{{ event.title }}</v-list-item-title>
                <v-list-item-subtitle v-html="event.description" class="mt-2"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CalendarView',
  data() {
    return {
      value: this.format(new Date()).split(' ')[0],
      loading: false,
      events: [],
      selected: [],
    }
  },
  methods: {
    getEvents(e) {
      this.load(e.start.date, e.end.date);
    },
    showEvent ({ date }) {
      console.log(date);
      this.selected = this.events.filter(e => this.between(date, e.start_date, e.end_date));
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    load(start, end) {
      fetch(`https://bcclimate.arkaive.org/wp-json/tribe/events/v1/events?start_date=${start}&end_date=${end}`)
        .then((r) => r.json())
        .then((data) => {
          this.events = data.events;
          this.showEvent({ date: this.format(new Date()).split(' ')[0] })
        });
    },
    between(date, start, end) {
      const d = new Date(date);
      const s = new Date(start.split(' ')[0]);
      const e = new Date(end.split(' ')[0]);
      return d >= s && d <= e;
    },
    format(date) {
      const y = date.getFullYear();
      const M = ('0' + (date.getMonth() + 1)).slice(-2);
      const d = ('0' + date.getDate()).slice(-2);
      const h = ('0' + date.getHours()).slice(-2);
      const m = ('0' + date.getMinutes()).slice(-2);
      return `${y}-${M}-${d} ${h}:${m}`;
    }
  }
}
</script>
