<template>
  <v-card 
    outlined 
    class=""
  >
    <v-btn-toggle
      v-model="selected"
      mandatory
      group
      dense
      class="pa-4"
      @change="change"
    >
      <v-btn
        v-for="(menu, index) in menus"
        :key="index"
        text
        :disabled="loading"
        class="rounded-pill"
        active-class="teal--text"
      >{{ menu.title }}</v-btn>
    </v-btn-toggle>

    <v-list class="overflow-y-auto">
      <v-list-item v-if="events.length === 0">
        <v-list-item-content>
          예정된 일정이 없습니다.
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(event, index) in events"
        :key="index"
        three-line
        link
        @click="$router.push('/calendar')"
      >
        <v-list-item-content>
          <v-list-item-subtitle class="text-caption">
            {{ event.start_date }}
          </v-list-item-subtitle>
          <v-list-item-title class="mt-2">{{ event.title }}</v-list-item-title>
          <v-list-item-subtitle v-html="event.description" class="mt-2"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'EventCard',
  components: {
  },
  data() {
    return {
      menus: [
        // { title: 'WEEK', time: 604800000 },
        { title: 'MONTH', time: 2629800000 },
        { title: 'YEAR', time: 31557600000 },
      ],
      selected: '',
      events: [],
      loading: false,
    }
  },
  methods: {
    change() {
      this.loading = true;
      this.events.length = 0;
      const startDate = new Date();
      const endDate = new Date(startDate.getTime() + this.menus[this.selected].time);
      const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
      const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
      fetch(`https://bcclimate.arkaive.org/wp-json/tribe/events/v1/events?start_date=${start}&end_date=${end}`)
        .then((r) => r.json())
        .then((data) => {
          this.events = data.events;
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.divider {
  border-bottom: thin solid #0000001f;
}
</style>
