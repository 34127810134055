function getFirstImage(html) {
  const parser = new DOMParser();
  const content = parser.parseFromString(html, 'text/html');
  const image = content.querySelector('img');
  return image ? image.src : null;
}

function getCategory(value) {
  const categories = [
    { id: 7, eng: 'notice', kor: '공지사항' },
    { id: 1, eng: 'news', kor: '뉴스' },
    { id: 5, eng: 'report', kor: '보고서' },
    { id: 4, eng: 'column', kor: '칼럼' },
    { id: 6, eng: 'event', kor: '행사' },
  ]
  const numPattern = /[0-9]/;
  const engPattern = /[a-zA-Z]/;
  
  if (numPattern.test(value)) {
    return categories.find((c) => c.id === value);
  } else if (engPattern.test(value)) {
    return categories.find((c) => c.eng === value);
  } else {
    return categories.find((c) => c.kor === value);
  }
}

function getRoute(el) {
  if (el.$route.name === 'home') {
    return 'home';
  } else if (el.$route.name === 'calendar') {
    return 'calendar';
  } else {
    return el.$route.params.category;
  }
}

export { getFirstImage, getCategory, getRoute };