<template>
  <div>
    <v-container>
      <v-row class="">
        <v-col cols="12" sm="7">
          <h1 v-html="headers[route].title" :style="`font-size: ${$vuetify.breakpoint.mobile ? '2' : '3'}rem`"></h1>
          <div v-if="route === 'home' && $vuetify.breakpoint.mobile" class="mt-4">
            <v-img src="https://images.unsplash.com/photo-1568994105591-922d787135dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1491&q=80" />
          </div>
          <div class="text-subtitle-1 pt-4" v-html="headers[route].text"></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" v-if="route === 'home'">
          <v-btn 
            rounded 
            color="teal" 
            dark
            class="mr-1"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfGd9SnA4KKeGqCQch55kGtJycFvEMXYP78p6Qfy8VNGTS4XQ/viewform"
            target="blank" 
          >참여하기</v-btn>
          <v-btn 
            v-for="(icon, index) in icons"
            :key="index"
            icon
            large
            link
            :href="icon.link"
            target="blank"
          >
            <v-icon>{{ icon.icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="route === 'home'">
      <div 
        v-if="!$vuetify.breakpoint.mobile"
        class="d-flex justify-center mt-8"
        style="overflow: hidden;"
      >
        <v-img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          :max-width="300"
          :max-height="320"
          class="ma-4 rounded-xl"
          :style="`transform: rotate(${index % 3 === 1 ? '2' : '-2'}deg)`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getRoute } from '@/scripts/utilities.js';

export default {
  name: 'HeroSection',
  data() {
    return {
      headers: {
        home: {
          title: '기후위기에 심각성을 느낀 전세계 185개국에서 일어나고 있는 "기후위기 비상행동(Climate strike)"',
          text: '' +
            '기후위기에 심각성을 느낀 전세계 185개국에서 일어나고 있는 “기후위기 비상행동(Climate strike)”.' +
            '부천에서도 기후위기를 걱정하는 시민들이 모여 "기후위기 부천비상행동"을 시작합니다.' +
            '지난 9월 첫걸음 캠페인을 시작으로 한 기후위기 부천비상행동은 현재 50여개의 부천시민사회단체와 함께하고 있습니다.'
        },
        notice: {
          title: '공지사항',
          text: '기후위기 부천비상행동의 공지사항을 알려드립니다.',
        },
        news: {
          title: '뉴스',
          text: '기후위기 부천비상행동이 정리한 기후와 관련한 소식들을 접해보세요.',
        },
        column: {
          title: '칼럼',
          text: '교수, 활동가, 시민 모두가 기후위기와 관련된 칼럼을 자유롭게 기고할 수 있습니다. ',
        },
        report: {
          title: '보고서',
          text: '기후위기 부천비상행동의 기행, 포럼 등의 주제와 연관하여 작성한 보고서를 볼 수 있습니다.',
        },
        event: {
          title: '행사',
          text: '기후위기 부천비상행동의 다양한  활동 및 행사 소식을 공유합니다.',
        },
        calendar: {
          title: '캘린더',
          text: '기후위기 부천비상행동의 행사 및 연대단체들의 활동을 한눈에 확인할 수 있습니다.',
        }
      },
      icons: [
        // { icon: 'mdi-alpha-n-box-outline', link: 'https://m.blog.naver.com/PostList.naver?blogId=climatestrikebucheon' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/1.5bisangbisang/' },
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/groups/834309257468047/?mibextid=6NoCDW' }
      ],
      images: [
        '/home-1.jpeg',
        '/home-2.jpeg',
        '/home-3.jpeg',
        '/home-4.jpeg',
        '/home-5.jpeg',
      ],
    }
  },
  computed: {
    route() {
      return getRoute(this);
    }
  },
  methods: {
  }
}
</script>
